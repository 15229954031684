import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { Toaster } from "react-hot-toast";
import { createRoot } from 'react-dom/client';
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import "./index.css";

// const root = document.getElementById("root");

const client = new QueryClient();

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
    <QueryClientProvider client={client}>
      <PersistGate persistor={persistor} loading={null}>
        <Toaster position="top-right" />
        <App />
      </PersistGate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);
