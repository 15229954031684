import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { registerLocale } from "react-datepicker";
import { Form, Formik } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as yup from "yup";
import ptBR from "date-fns/locale/pt-BR";
import { useMediaQuery } from "react-responsive";
import { ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { useDebounce } from "./filters.hooks";
import { Input } from "./Input";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectisClinicConnectionsComplete } from "../../store/slices/isClinicConnectionsCompleteSlice";

import styles from "./styles.module.css";
import { customSelectStyles } from './SelectStyles';

interface PatientProps {
  Id: number;
  SeqAtend: number;
  Paciente: string;
  Status: string;
  dataAtend: string;
  DataNasc: string;
  idade: string;
  indicacao: string;
  clinic: string;
  IdPaciente: number;
  Sexo: string;
  Telefone: string | null;
  Imagem: string;
  TodosExames: any[] | null;
  ImagensPaciente: any[] | null;
  Exames: any[] | null;
  Cefalometrias: any[] | null;
  ControleProcessos: any[] | null;
  VideosPaciente: any[] | null;
  TipoUsuario: string | null;
  SS: string;
}

interface FiltersProps {
  handleGetPatientsSeach: (patients: any) => void;
  resultSearch: any[];
  setResultSearch: React.Dispatch<React.SetStateAction<any[]>>;
  setResultRecommendation: React.Dispatch<React.SetStateAction<any[]>>;
  handleResetListPatients: () => void;
  patientList: PatientProps[];
  filteredPatientList: PatientProps[];
  setFilteredPatientList: React.Dispatch<React.SetStateAction<any[]>>;
  lastArrowChanged: any;
  listUser: any;
  updateListUser: any;
  setLastArrowChanged: React.Dispatch<React.SetStateAction<any>>;
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>;
  prevURL: string;
  setPrevURL: React.Dispatch<React.SetStateAction<string>>;
  setNoResultSearch?: any;
  handleChangeProcessos?: any;
  resultProcessos?: any;
  processo?: any;
  newRequestUpdateButton: () => void;
  updateCefxList: any;
}

type ClinicOption = {
  value: string;
  label: string;
};


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
    borderRadius: 12,
    padding: 10,
    border: '1px solid #d3d3d3',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#d3d3d3',
  },
}));

export function Filters({
  handleGetPatientsSeach,
  resultSearch,
  setResultSearch,
  setResultRecommendation,
  handleResetListPatients,
  patientList,
  filteredPatientList,
  setFilteredPatientList,
  lastArrowChanged,
  setLastArrowChanged,
  setNotFound,
  prevURL,
  setPrevURL,
  setNoResultSearch,
  handleChangeProcessos,
  resultProcessos,
  processo,
  newRequestUpdateButton,
  updateCefxList
}: FiltersProps) {

  registerLocale("pt-BR", ptBR);
  const animatedComponents = makeAnimated();

  const { user } = useSelector(selectUser);

  const [currentController, setCurrentController] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedDateForecast, setSelectedDateForecast] = useState(null);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [selectedRecomendations, setSelectedRecomendations] = useState([]);
  const [datePeriodRange, setDatePeriodRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = datePeriodRange;
  const [patientAge, setPatientAge] = useState([]);
  const [canGoPage1, setCanGoPage1] = useState(true);

  const [search, setSearch] = useState('');
  const [currentInputController, setCurrentInputController] = useState(null);
  const [currentCefxController, setCurrentCefxController] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isCefxSearchAborted, setIsCefxSearchAborted] = useState(false);
  const [isSearchAborted, setIsSearchAborted] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { isClinicConnectionsComplete } = useSelector(selectisClinicConnectionsComplete);

  const debouncedSearch = useDebounce(search, 300);
  const API_URL = process.env.REACT_APP_API_URL;
  const toConcludeCefx = localStorage.getItem('toConclude');
  const isMobileScreen = useMediaQuery({ query: "(max-width: 400px)" });
  const isIpadScreen = useMediaQuery({ query: "(max-width: 880px)" });
  const handleDateForecastChange = (date: any) => {
    setSelectedDateForecast(date);
  };


  const options = resultProcessos.map((processo) => ({
    value: processo.id,
    label: processo.name,
  }));

  const selectedOption = options.find(option => option.value === processo) || null;

  const handleButtonClick = (daysOffset) => {
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - Math.abs(daysOffset));
    const endDate = new Date();
    setDatePeriodRange([startDate, endDate]);
    setShowFilters(true);
  };

  const extractYearFromAgeString = (ageStr: string | undefined) => {
    if (!ageStr || typeof ageStr !== "string") return 0;

    const yearMatch = ageStr.match(/(\d+)a/);
    return yearMatch ? parseInt(yearMatch[1], 10) : 0;
  };

  const filterPatientsByAge = (initialAge, finalAge) => {
    setPatientAge([initialAge, finalAge]);
  };

  const formRef = useRef<any>();

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const handleSearchChange = (event: string): void => {
    if (event === "") {
      handleDeleteSearch();
    }
    setSearch(event);
  };

  const handleShowFilters = (setFieldValue) => {
    localStorage.setItem("patientFilters", null);
    window.location.reload();
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 10,
    }),
  };


  // const uniqueSituation = patientList?.reduce((acc, curr) => {
  //   if (!acc.includes(curr.indicacao)) {
  //     acc.push(curr.indicacao);
  //   }
  //   return acc;
  // }, []);

  const uniqueIndicacoes = patientList?.reduce((acc, curr) => {
    if (!acc.includes(curr?.indicacao)) {
      acc.push(curr.indicacao);
    }
    return acc;
  }, []);

  const uniqueClinics = patientList?.reduce((acc, curr) => {
    if (!acc.includes(curr?.clinic)) {
      acc.push(curr.clinic);
    }
    return acc;
  }, []);

  const optionsIndicacoes = uniqueIndicacoes?.map((indicacao) => ({
    value: indicacao,
    label: indicacao,
  }));

  const optionsClinic = uniqueClinics?.map((clinic) => ({
    value: clinic,
    label: clinic,
  }));

  const filterPatientsByDate = (startDate, endDate, patients) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    return patients.filter((patient) => {
      const patientDate = new Date(patient.dataAtend);
      return patientDate >= start && patientDate <= end;
    });
  };

  const navigatePage1 = () => {
    if (filteredPatientList.length > 0) {
      navigate("/dashboard/lista-pacientes?page=1");
    }
  };

  // Inicio requisiçoes para api. listar pacientes e pacientes cefx
  const fetchData = async (userData, debouncedSearch, signal) => {
    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const cleanSearch = removeAccents(debouncedSearch);

    try {
      const result = await Api.GetPatientList(
        {
          idClient: userData.idClient,
          hash: userData.hash,
        },
        cleanSearch,
        signal
      );

      if (result.isSuccess && result.data.patients.length > 0) {
        const clinicPatients = result.data.patients.map((patient) => ({
          dataAtend: patient.dataAtend,
          idade: patient.idade,
          dataNasc: patient.dataNasc,
          paciente: patient.paciente,
          indicacao: patient.indicacao,
          clinic: userData.clinicalName,
          status: patient.status,
          id: patient.id,
          ss: patient.ss,
          exames: patient.exames,
          imagem: patient.imagem.includes('https://app.evolcloud.com.br')
            ? 'https://cdt2so.blob.core.windows.net/3889/061922/93d00bdb-823c-4e3a-a0c0-8dcb3894fedf/dcm/00/inicio.png'
            : `${API_URL}${patient.imagem}`,
          seqAtend: patient.seqAtend,
          idClient: userData.idClient,
          idPaciente: patient.idPaciente,
        }));

        return clinicPatients;
      }
    } catch (error) {
    }

    return [];
  };

  const fetchDataCefx = async (userData, debouncedSearch, signal) => {
    setSearchLoading(true);

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const cleanSearch = removeAccents(debouncedSearch);

    try {
      const resultAllClinics = await Api.GetUserClinicaByUserId({
        userId: userData.idUser,
      });

      if (resultAllClinics.data.length > 0) {
        const clinicRequests = resultAllClinics.data.map(async (clinic) => {
          try {
            const result = await Api.GetPatientListCefx(
              {
                idClient: clinic.idClient,
                hash: clinic.hash || userData.hash,
                page: 0,
              },
              {
                process_id: -2,
                to_conclude: toConcludeCefx,
              },
              {
                searchName: cleanSearch,
                signal,
              }
            );

            if (result.isSuccess) {
              return result.data.patients.map((patient) => ({
                dataAtend: patient.dataAtend,
                idade: patient.idade,
                dataNasc: patient.idade,
                paciente: patient.paciente,
                indicacao: patient.indicacao,
                clinic: clinic.clinicalName,
                status: patient.status,
                id: patient.id,
                ss: patient.ss,
                imagem: `${API_URL}${patient.imagem}`,
                seqAtend: patient.seqAtend,
                idClient: clinic.idClient,
                idPaciente: patient.idPaciente,
              }));
            } else if (result.data.patients.length === 0) {
              return [];
            }
          } catch (error) {
            console.error(`Erro ao obter pacientes da clínica ${clinic.idClient}: ${error.message}`);
            return [];
          }
        });

        const clinicResults = await Promise.all(clinicRequests);
        return clinicResults.flat();
      } else {
        const result = await Api.GetPatientListCefx(
          {
            idClient: userData.idClient,
            hash: userData.hash,
            page: 0,
          },
          {
            process_id: -2,
            to_conclude: toConcludeCefx,
          },
          {
            searchName: cleanSearch,
            signal,
          }
        );

        if (result.isSuccess) {
          return result.data.patients.map((patient) => ({
            dataAtend: patient.dataAtend,
            idade: patient.idade,
            dataNasc: patient.idade,
            paciente: patient.paciente,
            indicacao: patient.indicacao,
            clinic: userData.clinicalName,
            status: patient.status,
            id: patient.id,
            ss: patient.ss,
            imagem: `${API_URL}${patient.imagem}`,
            seqAtend: patient.seqAtend,
            idClient: userData.idClient,
            idPaciente: patient.idPaciente,
          }));
        } else if (result.data.patients.length === 0) {
          return [];
        }
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const buscarPaciente = useCallback(() => {
    if (!isCefalometria && search.length > 1 && user && user.length > 0) {

      setSearchLoading(true);

      if (currentInputController) {
        currentInputController.abort();
      }

      const newController = new AbortController();
      setCurrentInputController(newController);
      setIsSearchAborted(false);

      let newSearchPatients = [];
      let anyPatientsFound = false;
      let activeRequests = user.length;

      (async () => {
        try {
          for (const userData of user) {
            const patientsFound = await fetchData(userData, search, newController.signal);

            activeRequests--;

            if (patientsFound.length > 0) {
              anyPatientsFound = true;
              newSearchPatients = [...newSearchPatients, ...patientsFound];
              setResultSearch(newSearchPatients);
            }

            if (activeRequests === 0 && !newController.signal.aborted) {
              setSearchLoading(false);
              setNoResultSearch(!anyPatientsFound);
            }
          }
        } catch (error) {
          activeRequests--;

          if (activeRequests === 0 && !newController.signal.aborted) {
            setSearchLoading(false);
            setNoResultSearch(!anyPatientsFound);
          }

          setResultSearch([]);
          handleResetListPatients();
        } finally {
          if (activeRequests === 0 && !newController.signal.aborted) {
            setSearchLoading(false);
            setNoResultSearch(!anyPatientsFound);
          }
        }
      })();
    }
  }, [search, user, isCefalometria, currentInputController]);

  const buscarPacienteCefx = useCallback(() => {
    if (isCefalometria && search.length > 1 && user && user.length > 0) {
      setSearchLoading(true);

      if (currentCefxController) {
        currentCefxController.abort();
      }

      const newController = new AbortController();
      setCurrentCefxController(newController);
      setIsCefxSearchAborted(false);

      Promise.all(user.map((userData) => fetchDataCefx(userData, search, newController.signal)))
        .then((results) => {
          const allResults = results.flat();

          setIsCefxSearchAborted((isAborted) => {
            if (!isAborted) {
              handleGetPatientsSeach(allResults);
              if (allResults.length === 0 && !newController.signal.aborted) {
                setNoResultSearch(true);
              } else {
                setNoResultSearch(false);
              }
            }
            return isAborted;
          });

          if (!newController.signal.aborted) {
            setSearchLoading(false);
          }
        })
        .catch((error) => {
          if (!newController.signal.aborted) {
            console.error(error);
            setNoResultSearch(true);
            setSearchLoading(false);
          }
        });
    }
  }, [search, user, isCefalometria, handleGetPatientsSeach, currentCefxController]);

  const handleDeleteSearch = useCallback(() => {
    if (currentInputController) {
      currentInputController.abort();
    } else if (currentCefxController) {
      currentCefxController.abort();
    }

    setResultSearch([]);
    handleResetListPatients();
    setSearch("");
    setSearchLoading(false);
  }, [currentInputController, currentCefxController, handleResetListPatients]);

  useEffect(() => {
    return () => {
      if (currentInputController) {
        currentInputController.abort();
      }
      if (currentCefxController) {
        currentCefxController.abort();
      }
    };
  }, [currentInputController, currentCefxController]);
  // FIM requisiçoes para api. listar pacientes e pacientes cefx

  useEffect(() => {
    let filteredPatients = [...patientList];

    const applySearchFilter = () => {
      if (debouncedSearch !== "" && search !== "" && !searchLoading && resultSearch.length > 0) {

        if (resultSearch.length > 0) {

          filteredPatients = resultSearch.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.id === obj.id)
          );
        } else {
          filteredPatients = [];
        }
      }
    };

    // const applyDateFilter = () => {
    //   if (startDate && endDate) {
    //     endDate.setHours(23, 59, 59);
    //     filteredPatients = filterPatientsByDate(
    //       startDate,
    //       endDate,
    //       filteredPatients
    //     );
    //   }
    // };

    // const applyAgeFilter = () => {
    //   if (patientAge.length === 2) {
    //     const [initialAge, finalAge] = patientAge;
    //     filteredPatients = filteredPatients.filter((patient) => {
    //       const patientAge = extractYearFromAgeString(patient.idade);
    //       return patientAge >= initialAge && patientAge <= finalAge;
    //     });
    //   }
    // };

    const applyClinicsFilter = () => {
      if (selectedClinics.length > 0) {
        const clinicNames = selectedClinics.map((option) => option.value);
        filteredPatients = filteredPatients.filter((patient) =>
          clinicNames.includes(patient.clinic)
        );
      }
    };

    const applyRecomendationsFilter = () => {
      if (selectedRecomendations.length > 0) {
        const recomendationNames = selectedRecomendations.map(
          (option) => option.value
        );
        filteredPatients = filteredPatients.filter((patient) =>
          recomendationNames.includes(patient.indicacao)
        );
      }
      // handleGetPatientsSeach(filteredPatients)
    };

    const applyArrowChange = () => {
      if (lastArrowChanged) {
        filteredPatients = reorder(
          lastArrowChanged.type,
          lastArrowChanged.prop,
          filteredPatients
        );
      }
    };

    const applyFilters = () => {
      applySearchFilter();
      //   applyDateFilter();
      // applyAgeFilter();
      applyClinicsFilter();
      applyRecomendationsFilter();
      applyArrowChange();

      if (filteredPatients.length === 0) {
        setNotFound(true);
      }

      setFilteredPatientList(filteredPatients);
    };

    applyFilters();

    const filters = {
      search,
      resultSearch,
      startDate,
      endDate,
      selectedClinics,
      selectedRecomendations,
      patientAge,
      lastArrowChanged,
    };

    localStorage.setItem("patientFilters", JSON.stringify(filters));
  }, [
    search,
    searchLoading,
    startDate,
    endDate,
    patientList,
    selectedClinics,
    selectedRecomendations,
    patientAge,
    lastArrowChanged,
  ]);

  useLayoutEffect(() => {
    const storagedFilters = JSON.parse(localStorage.getItem("patientFilters"));

    const applyFilter = (key, setter, condition = true) => {
      if (key === "resultSearch" && condition) {
        setSearchLoading(false);
      }

      if (condition && storagedFilters[key]) {
        setter(storagedFilters[key]);
      }
    };

    if (storagedFilters) {
      // applyFilter("search", setSearch) Aqui está comitado pq quando volta para pagina lista de pacientes o campo buscar pacientes nao ficar preenchido;

      if (storagedFilters.startDate && storagedFilters.endDate) {
        const startDate = new Date(storagedFilters.startDate);
        const endDate = new Date(storagedFilters.endDate);
        setDatePeriodRange([startDate, endDate]);
      }

      // applyFilter(
      //   "patientAge",
      //   setPatientAge,
      //   storagedFilters.patientAge && storagedFilters.patientAge.length > 0
      // );
      applyFilter(
        "selectedRecomendations",
        setSelectedRecomendations,
        storagedFilters.selectedRecomendations &&
        storagedFilters.selectedRecomendations.length > 0
      );
      applyFilter(
        "selectedClinics",
        setSelectedClinics,
        storagedFilters.selectedClinics &&
        storagedFilters.selectedClinics.length > 0
      );
      applyFilter(
        "resultSearch",
        setResultSearch,
        storagedFilters.resultSearch && storagedFilters.resultSearch.length > 0
      );

      applyFilter("lastArrowChanged", setLastArrowChanged);

      const shouldDisablePage1 =
        storagedFilters.selectedClinics.length > 0 ||
        (storagedFilters.startDate !== null &&
          storagedFilters.endDate !== null) ||
        storagedFilters.selectedRecomendations.length > 0 ||
        storagedFilters.patientAge.length > 0 ||
        storagedFilters.search.length > 0;

      if (shouldDisablePage1) {
        setCanGoPage1(false);
        localStorage.setItem("patientFilters", null);
      }
    }
  }, []);

  useEffect(() => {
    //Modo Cefx filtro para indicaçao!
    if (selectedRecomendations.length > 0 && isCefalometria) {
      let filteredPatients = [...patientList];

      const recomendationNames = selectedRecomendations.map(
        (option) => option.value
      );
      filteredPatients = filteredPatients.filter((patient) =>
        recomendationNames.includes(patient.indicacao)
      );
      setResultSearch(filteredPatients)
    } else {
      setResultSearch([])
    }
  }, [selectedRecomendations])

  useEffect(() => {
    if (
      !isCefalometria &&
      selectedClinics.length > 0 ||
      (startDate !== null && endDate !== null) ||
      selectedRecomendations.length > 0 ||
      patientAge.length > 0 ||
      resultSearch.length > 0
    ) {
      if (location.search) setPrevURL(`${location.pathname}${location.search}`);

      if (canGoPage1) {
        navigatePage1();
      } else {
        setCanGoPage1(true);
      }
    } else if (prevURL) {
      navigate(prevURL);
    }
  }, [
    selectedClinics,
    startDate,
    endDate,
    selectedRecomendations,
    patientAge,
    resultSearch,
  ]);

  const reorder = (type, prop, list?) => {
    const listToSort = list ? [...list] : [...filteredPatientList];
    const reordered = listToSort.sort((a, b) => {
      if (a[prop] < b[prop]) {
        if (type === "up") {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[prop] > b[prop]) {
        if (type === "up") {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
    return reordered;
  };

  return (
    <div
      className={showFilters ? `${styles.wrapper}` : `${styles.wrapper_active}`}
    >
      <Formik
        initialValues={{
          patient: "",
          situation: "",
          clinic: "",
          period: "",
          forecast: "",
          age: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={() => { }}
        innerRef={formRef}
      >
        {({ errors, isSubmitting, values, setFieldValue }) => (
          <Form
            className={styles.form}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
          >
            <div className={styles.containerInputs}>

              <div className={styles.containerInputPatientAndButton}>
                <Input
                  className={styles.containerInputswidth}
                  value={search}
                  name="patient"
                  type="text"
                  placeholder="Buscar"
                  onChange={(e) => handleSearchChange(e.target.value)}
                  loadingicon={searchLoading}
                  handleDeleteSearch={handleDeleteSearch}
                  onEnterPress={() => !isCefalometria ? buscarPaciente() : buscarPacienteCefx()}
                />
                <button
                  type="button"
                  className={styles.containerbuttonPesquisa}
                  onClick={() => !isCefalometria ? buscarPaciente() : buscarPacienteCefx()}
                >
                  {
                    isIpadScreen ?
                      <MagnifyingGlassIcon width={18} />
                      :
                      <span>Pesquisar</span>
                  }
                </button>
              </div>

              <div className={styles.containerSelectsAndButtonUpdate}>
                {!isMobileScreen && (
                  <>
                    <Select
                      name="recomendation"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Indicação..."
                      styles={customSelectStyles}
                      id="recomendation"
                      onChange={(selectedOptions: ClinicOption[] | null) => {
                        setSelectedRecomendations(selectedOptions || []);
                      }}
                      options={optionsIndicacoes}
                      value={selectedRecomendations}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      isClearable={true}
                      isSearchable={false}
                      isMulti
                    />

                    {
                      isCefalometria && (
                        <Select
                          value={selectedOption}
                          onChange={(selectedOption) => handleChangeProcessos({
                            target: {
                              value: selectedOption ? selectedOption.value : '',
                            },
                          })}
                          options={options}
                          placeholder={
                            processo === -2
                              ? 'Processo...'
                              : resultProcessos.find(p => p.id === processo)?.name || 'Escolha um processo...'
                          }
                          styles={customSelectStyles}
                          isClearable={true}
                          isSearchable={false}
                        />
                      )
                    }
                  </>
                )}

                {!isMobileScreen && !isCefalometria && !isIpadScreen && (
                  <div className={styles.loading_container}>
                    {!isClinicConnectionsComplete && (
                      <LightTooltip
                        title="Aguardando todas as conexões das clínicas"
                        arrow
                        placement="top"
                        open
                        disableHoverListener
                        disableFocusListener
                        disableTouchListener
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'preventOverflow',
                              options: {
                                boundary: 'clippingParents',
                              },
                            },
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10],
                              },
                            },
                            {
                              name: 'flip',
                              options: {
                                enabled: false,
                              },
                            },
                            {
                              name: 'update',
                              options: {
                                enabled: false,
                              },
                            },
                          ],
                        }}
                      >
                        <div className={styles.loading_spinnes} />
                      </LightTooltip>
                    )}

                    <Select
                      name="clinic"
                      className={styles.selectOption}
                      placeholder="Clínica"
                      styles={customSelectStyles}
                      id="clinic"
                      onChange={(selectedOptions: ClinicOption[] | null) => {
                        setSelectedClinics(selectedOptions || []);
                      }}
                      options={optionsClinic}
                      value={selectedClinics}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      isClearable={true}
                      isSearchable={false}
                      isMulti
                    />
                  </div>
                )
                }

                {
                  !isMobileScreen && (
                    isCefalometria ? (
                      <button
                        type="button"
                        title={
                          updateCefxList
                          && "Aguardando conexões de todas as clínicas"
                        }
                        onClick={newRequestUpdateButton}
                        className={styles.buttonUpdate}
                        disabled={updateCefxList}
                        style={
                          {
                            cursor: updateCefxList && 'not-allowed',
                            opacity: updateCefxList && '0.5'
                          }
                        }
                      >
                        <ArrowPathIcon width={20} />
                        Atualizar
                      </button>
                    )
                      : (
                        <button
                          type="button"
                          className={styles.buttonUpdate}
                          style={{
                            opacity: isClinicConnectionsComplete ? '1' : '0.5',
                            cursor: isClinicConnectionsComplete ? 'pointer' : 'not-allowed',
                          }}
                          title={
                            isClinicConnectionsComplete
                              ? "Atualizar lista de pacientes"
                              : "Aguardando conexões de todas as clínicas"
                          }
                          onClick={newRequestUpdateButton}
                          disabled={!isClinicConnectionsComplete}
                        >
                          <ArrowPathIcon width={20} />
                          ATUALIZAR
                        </button>
                      )
                  )
                }
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
