import Modal from "react-modal";
import styles from "./styles.module.css";
import { useState } from "react";
import { XMarkIcon, ShareIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";
import Api from "../../../../helpers/Api";
import { ModalErrorOrSucessMessage } from "../../../../components/ModalErrorOrSucessMessage";
import {
  createExamRequest,
  getExamRequest,
  sendExamRequest,
  updateExamRequest,
} from "../../utils/apiConnection";
import { useEffect } from "react";

interface ModalSendRequestProps {
  isOpen: boolean;
  onRequestClose: () => void;
  apiKey: string;
  hashClinic: string;
  content: HTMLElement;
  idUser: number;
  idClient: string;
  idPatient: number;
  examRequestToEdit: any;
  setExamRequestUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setModalErrorOrSucessMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<string>>;
  restartStyles: () => void;
}

export const ModalSendRequest = ({
  isOpen,
  onRequestClose,
  apiKey,
  hashClinic,
  content,
  idClient,
  idUser,
  idPatient,
  examRequestToEdit,
  setExamRequestUpdate,
  setModalErrorOrSucessMessage,
  setModalType,
  restartStyles,
}: ModalSendRequestProps) => {
  const [isRequestLoading, setRequestLoading] = useState(false);

  const handleSendRequest = async (action: string, requestName: string) => {
    setRequestLoading(true);
    const requestData = {
      active: true,
      name: requestName,
      html: transformToFullHTML(String(content)),
      idClient,
      idPatient,
      idUser,
      // status,
      // cep,
      // cpf,
      // rg,
      // endereco,
      // email,
      createdDate: examRequestToEdit
        ? new Date(examRequestToEdit.createdDate).toISOString()
        : new Date().toISOString(),
    };
    if (examRequestToEdit && action === "update") {
      requestData["code"] = examRequestToEdit.code;
      await updateExamRequest(
        examRequestToEdit.id,
        requestData,
        hashClinic,
        apiKey,
        setModalType,
        setExamRequestUpdate
      );
    } else if (action === "send") {
      await sendExamRequest(requestData, apiKey, hashClinic, setModalType);
      setRequestLoading(false);
    } else if (action === "create") {
      await createExamRequest(requestData, apiKey, hashClinic, setModalType);
      setRequestLoading(false);
    }
    onRequestClose();
    setModalErrorOrSucessMessage(true);
  };

  const validationSchema = Yup.object({
    requestName: Yup.string().required("O nome da requisição é obrigatório!"),
  });

  const handleSubmit = async (values, action) => {
    if (values.requestName) {
      await handleSendRequest(action, values.requestName);
    }
  };

  const transformToFullHTML = (html: string) => {
    const template = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
          <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
        </head>
        <body style="font-family: SEGOE UI"><div class="ql-editor" style="margin: 0px; padding: 0px;">${html}</div>
        </body>
      </html>
    `;

    return template;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react_modal_overlay"
      className={styles.react_modal_Send_Request}
    >
      <div className={styles.xIconWrapper}>
        <XMarkIcon
          title="Fechar"
          onClick={() => {
            restartStyles();
            onRequestClose();
          }}
        />
      </div>

      <div className={styles.shareIconWrapper}>
        <div className={styles.circle}>
          <ShareIcon width={24} />
        </div>
      </div>

      <div className={styles.textContainer}>
        <h2>Enviar Requisição ao Paciente</h2>
        <p>Escolha o modo em que deseja compartilhar</p>
      </div>

      <Formik
        initialValues={{
          requestName: examRequestToEdit ? examRequestToEdit.name : "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <div className={styles.wrapper_input}>
              <label htmlFor="modelName" className={styles.label}>
                Nome da requisição
              </label>
              <Field
                id="requestName"
                name="requestName"
                type="text"
                placeholder="Nome da requisição"
                required
                className={styles.requestName}
              />
              <ErrorMessage
                name="requestName"
                component="p"
                className={styles.error}
              />
            </div>

            {isRequestLoading ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress sx={{ color: "#006E5A" }} size={30} />
                </div>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Aguarde...
                </p>
              </div>
            ) : (
              <div className={styles.buttonsContainer}>
                {!examRequestToEdit && (
                  <div className={styles.containerButtonWhatsapp}>
                    <button
                      type="submit"
                      onClick={() => {
                        handleSubmit(values, "create");
                      }}
                    >
                      Salvar
                    </button>
                  </div>
                )}

                <div className={styles.containerButtonEmail}>
                  <button
                    type="submit"
                    onClick={() => {
                      if (examRequestToEdit) {
                        handleSubmit(values, "update");
                      } else {
                        handleSubmit(values, "send");
                      }
                    }}
                  >
                    {!examRequestToEdit ? "Via E-mail" : "Atualizar Requisição"}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
