import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, ErrorInfo, useRef } from "react";
import { Avatar } from "@cdt-ui/react";
import Api from "../../helpers/Api";
import { format } from "date-fns";
import { setpatients } from "../../store/slices/patientListSlice";

import { useNavigate } from "react-router-dom";

import { selectUser } from "../../store/slices/userSlice";
import { selectPatientsList } from "../../store/slices/patientListSlice";

import { Filters } from "./fIlterExamsRequest/Filters";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";

import {
  getAllExamsRequest,
  getExamRequest,
  updateExamRequest,
} from "./utils/apiConnection";

import styles from "./styles.module.css";

import ReactPaginate from "react-paginate";

import { useMediaQuery } from "react-responsive";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { Table } from "./tableExamsRequest";
import { TableSkeleton } from "./components/tableSkeleton";
import { ModalDeleteExamRequest } from "./Modals/ModalDeleteExamRequest";

export function ExamsRequest() {
  const { user } = useSelector(selectUser);
  const idUser = user[0].idUser;
  const hashUser = user[0].hash;
  const [selectedSituation, setSelectedSituation] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [datePeriodRange, setDatePeriodRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);

  const [examsRequest, setExamsRequest] = useState([]);

  const [filteredExamsRequest, setFilteredExamsRequest] =
    useState(examsRequest);

  const [search, setSearch] = useState("");
  const [wasUpdate, setUpdate] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [selectedExamsRequest, setSeletectedExamsRequest] = useState([]);
  const [isModalDeleteExamRequestOpen, setModalDeleteExamRequestOpen] =
    useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  const apiKey = process.env.REACT_APP_EXAM_REQUEST_API_KEY;

  const handleOpenModalDeleteExamRequest = () => {
    setModalDeleteExamRequestOpen(true);
  };

  const handleCloseModalDeleteExamRequest = () => {
    setModalDeleteExamRequestOpen(false);
  };

  const getUser = async (id: number) => {
    try {
      const response = await Api.GetuserById(id);
      if (response.isSuccess) {
        return response.data;
      }
      return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const deleteExamRequest = async (id: number) => {
    setUpdate(false);
    const examRequestData = await getExamRequest(id, hashUser, apiKey);
    if (examRequestData) {
      examRequestData.active = false;
      await updateExamRequest(
        id,
        examRequestData,
        hashUser,
        apiKey,
        null,
        setUpdate
      );
      setSeletectedExamsRequest((prevSelectedExamsRequest) =>
        prevSelectedExamsRequest.filter((selected) => selected != id)
      );
    }
  };

  const fetchExamsRequest = async () => {
    const examsRequestData = await getAllExamsRequest(
      idUser,
      hashUser,
      apiKey
    );
    const userResponse = await getUser(idUser);
    examsRequestData.map(async (exam) => {
      userResponse.nomePessoal
        ? (exam["dentist"] = userResponse.nomePessoal)
        : (exam["dentist"] = "");
    });

    setExamsRequest(examsRequestData);
    setFilteredExamsRequest(examsRequestData);
    setLoading(false);
  };

  useEffect(() => {
    fetchExamsRequest();
  }, [user, wasUpdate, idUser, hashUser]);

  const fetchClinics = async () => {
    try {
      const response = await Api.getClinics(idUser);
      if (response.isSuccess) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const updateExamsRequest = (data: any[]) => {
    setFilteredExamsRequest(data);
  };

  useEffect(() => {
    const filterExamsRequest = async () => {
      let filtered = examsRequest;
      let clinics = await fetchClinics();

      if (search) {
        filtered = filtered.filter((examRequest) => {
          return examRequest.name.toLowerCase().includes(search.toLowerCase());
        });
      }

      if (
        datePeriodRange.length === 2 &&
        datePeriodRange[0] instanceof Date &&
        datePeriodRange[1] instanceof Date
      ) {
        filtered = filtered.filter((examRequest) => {
          const [startDate, endDate] = datePeriodRange;
          const start = new Date(startDate);
          start.setHours(0, 0, 0, 0);
          const end = new Date(endDate);
          end.setHours(23, 59, 59, 9999);
          const examDate = new Date(examRequest.createdDate);
          return examDate >= start && examDate <= end;
        });
      }

      /*       if (selectedSituation) {
        filtered = filtered.filter((examRequest) => {
          return (
            selectedSituation.label.toLowerCase() ===
            examRequest.situation.toLowerCase()
          );
        });
      } */

      if (selectedClinic) {
        const clinicMatches = clinics.filter(
          (clinic) =>
            clinic.clinicalName.toLowerCase() ===
            selectedClinic.label.toLowerCase()
        );

        if (clinicMatches.length > 0) {
          const idClient = clinicMatches[0].idClient;
          filtered = filtered.filter(
            (examRequest) => examRequest.idClient === idClient
          );
        }
      }

      setFilteredExamsRequest(filtered);
    };

    filterExamsRequest();
  }, [
    examsRequest,
    search,
    datePeriodRange,
    selectedSituation,
    selectedClinic,
  ]);

  const handleChangeSelectedExamsRequest = (id: number) => {
    if (selectedExamsRequest.includes(id)) {
      setSeletectedExamsRequest((prevExams) =>
        prevExams.filter((exam) => exam !== id)
      );
    } else {
      setSeletectedExamsRequest((prevExams) => [...prevExams, id]);
    }
  };

  const isSelected = (id: number) => {
    return selectedExamsRequest.includes(id);
  };

  const selectAllExamsRequest = () => {
    if (selectedExamsRequest.length === examsRequest.length) {
      setSeletectedExamsRequest([]);
    } else {
      setSeletectedExamsRequest(examsRequest.map((exam) => exam.id));
    }
  };

  const isAllSelected = () => {
    return (
      JSON.stringify(selectedExamsRequest) ===
      JSON.stringify(examsRequest.map((exam) => exam.id))
    );
  };

  const handleDeleteExamRequest = async () => {
    selectedExamsRequest.map(async (id) => await deleteExamRequest(id));
    /* setUpdate(true); */
    handleCloseModalDeleteExamRequest();
  };

  const handleConfirmDeleteUnique = (id: number) => {
    if (selectedExamsRequest.length === 0) {
      handleChangeSelectedExamsRequest(id);
    }
    handleOpenModalDeleteExamRequest();
  };

  const handleConfirmDeleteSelecteds = () => {
    handleOpenModalDeleteExamRequest();
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.container_filterAndVideo}>
          <Filters
            search={search}
            setSearch={setSearch}
            examsRequest={examsRequest}
            datePeriodRange={datePeriodRange}
            setDatePeriodRange={setDatePeriodRange}
            selectedSituation={selectedSituation}
            setSelectedSituation={setSelectedSituation}
            selectedClinic={selectedClinic}
            setSelectedClinic={setSelectedClinic}
          />

          {examsRequest.length > 0 && !isLoading ? (
            <>
              <Table
                amount={examsRequest.length}
                examsRequest={filteredExamsRequest}
                currentData={filteredExamsRequest.slice(
                  offset,
                  offset + itemsPerPage
                )}
                updateExamsRequest={updateExamsRequest}
                isSelected={isSelected}
                handleChangeExamsRequest={handleChangeSelectedExamsRequest}
                isAllSelected={isAllSelected}
                selectAllExamsRequest={selectAllExamsRequest}
                handleConfirmDeleteUnique={handleConfirmDeleteUnique}
                handleConfirmDeleteSelecteds={handleConfirmDeleteSelecteds}
              />


              <div className={styles.containerPaginationExames}>
                <ReactPaginate
                  previousLabel={<ChevronLeftIcon width={12} />}
                  nextLabel={<ChevronRightIcon width={12} />}
                  breakLabel={"..."}
                  pageCount={Math.ceil(
                    filteredExamsRequest.length / itemsPerPage
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={styles.pagination}
                  activeClassName={styles.active}
                />
              </div>

            </>
          ) : isLoading ? (
            <TableSkeleton />
          ) : (
            <div className={styles.containerTextUserFalse}>
              <p>Atualmente, não foi feita nenhuma requisição de exames.</p>
            </div>
          )}
        </div>
      </div>
      {isModalDeleteExamRequestOpen && (
        <ModalDeleteExamRequest
          isOpen={isModalDeleteExamRequestOpen}
          onRequestClose={handleCloseModalDeleteExamRequest}
          handleDeleteExamRequest={handleDeleteExamRequest}
        />
      )}
    </section>
  );
}
