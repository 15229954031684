import { createContext, useState, useEffect, useRef } from "react";
import Api from "../../helpers/Api";
import { selectUser } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPatientsList
} from "../../store/slices/patientListSlice";
import { selectisClinicConnectionsComplete } from "../../store/slices/isClinicConnectionsCompleteSlice";

import { addPatientCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { addPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { addPatientCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";

import { selectPatientsCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { selectPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { selectPatientsCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";
import { useReloadPatientsList } from "../../hooks/useReloadPatientsLists";

import { selectTempPatientsList } from "../../store/slices/tempPatientListSlice";
import { selectIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { selectProcesso } from "../../store/slices/processoSlice";
import { selectToConcludeCefx } from "../../store/slices/toConcludeCefxSlice";
import { markConcludeLoaded } from "../../store/slices/loadedConcludesSlices";

export const PatientContext = createContext(null);

export const PatientProvider = ({ children }) => {
  const dispatch = useDispatch();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleReloadList = useReloadPatientsList();

  const { patientsCefxAConcluir, lastPageLoaded, totalPages } = useSelector(selectPatientsCefxAConcluir);
  const { patientsCefxConcluidos } = useSelector(selectPatientCefxConcluidos);
  const { patientsCefxEmAndamento } = useSelector(
    selectPatientsCefxEmAndamento
  );

  const { user } = useSelector(selectUser);
  const { isCefalometria } = useSelector(selectIsCefalometria);
  const { toConcludeCefx } = useSelector(selectToConcludeCefx);
  const { processo } = useSelector(selectProcesso);

  const [patientList, setPatientList] = useState([]);
  const [filteredPatientList, setFilteredPatientList] = useState([]);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [shouldAbort, setShouldAbort] = useState(false);
  const [noPatientsCefx, setNoPatientsCefx] = useState(false);
  const [loadingButtonChecklist, setLoadingButtonCheckList] = useState(false);
  const [resultProcessos, setResultProcessos] = useState([]);
  const [updateCefxList, setUpdateCefxList] = useState(false);

  const abortControllerRef = useRef(null);

  const reorderedClinics = useRef([]);

  const resetPatientLoading = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    handleReloadList();

    setPatientList([]);
    setShouldAbort(true);
    setFilteredPatientList([]);
  };

  const reactivePatientsLoading = () => {
    if (user && user.length > 0) {
      resetPatientLoading();
    }
  };

  // //Inicio Pacientes CEFX
  const toConcludeRef = useRef(Number(toConcludeCefx));
  const toProcessoRef = useRef(null);

  const cefxPatientIsLoaded = useSelector((state: any) => {
    const loaded = state.loadedConcludes[toConcludeCefx] ?? false;

    let dataExists = false;

    switch (toConcludeCefx) {
      case 0:
        dataExists = patientsCefxAConcluir.length > 0;
        break;
      case 1:
        dataExists = patientsCefxConcluidos.length > 0;
        break;
      case 2:
        dataExists = patientsCefxEmAndamento.length > 0;
        break;
      default:
        break;
    }

    return loaded && dataExists;
  });

  useEffect(() => {
    const abortController = new AbortController();

    if (cefxPatientIsLoaded) {
      setLoadingButtonCheckList(false);
      return;
    };

    if (isCefalometria && (!cefxPatientIsLoaded || updateCefxList)) {
      setLoadingButtonCheckList(true);
      setNoPatientsCefx(false);

      const { signal } = abortController;

      const fetchData = async () => {
        try {
          let allPatients = [];
          for (const currentUser of user) {
            const { idUser, idClient, hash, roles, clinicalName } = currentUser;
            let resultAllClinics = [];

            if (roles === "NAOAUTENTICADO") {
              resultAllClinics.push({ idClient, clinicalName });
            } else {
              const result = await Api.GetUserClinicaByUserId({ userId: idUser }, { signal });
              resultAllClinics = result.data;
            }

            for (const clinic of resultAllClinics) {
              let currentPage = 0;
              let totalPages = Infinity;

              while (currentPage < totalPages) {
                if (signal.aborted) return;

                const response = await Api.GetPatientListCefx(
                  { idClient, hash, page: currentPage },
                  { process_id: processo, to_conclude: toConcludeCefx },
                  { signal }
                );

                if (response.isSuccess) {
                  const { patients, totalPages: total } = response.data;

                  totalPages = total > 10 ? 10 : total;

                  if (patients.length === 0) {
                    setNoPatientsCefx(true);
                    setUpdateCefxList(false);
                  } else {
                    setNoPatientsCefx(false)
                  }

                  const patientsWithIP = patients.map(patient => ({
                    ...patient,
                    clinic: clinicalName,
                    imagem: `${API_URL}${patient.imagem}`,
                  }));

                  allPatients.push(...patientsWithIP);

                  switch (toConcludeCefx) {
                    case 0:
                      dispatch(addPatientCefxAConcluir(allPatients));
                      break;
                    case 1:
                      dispatch(addPatientCefxConcluidos(allPatients));
                      break;
                    case 2:
                      dispatch(addPatientCefxEmAndamento(allPatients));
                      break;
                    default:
                      break;
                  }

                  currentPage++;
                  setLoadingButtonCheckList(false);
                } else {
                  console.error("A requisição falhou:", response.errorMessage);
                  break;
                }
              }
            }
          }

        } catch (error) {

        } finally {
          setUpdateCefxList(false);
          // setLoadingButtonCheckList(false);
        }
      };

      dispatch(markConcludeLoaded(toConcludeCefx));
      fetchData();

      return () => {
        abortController.abort();
      };
    }
  }, [isCefalometria, toConcludeCefx, processo, updateCefxList]);

  useEffect(() => {
    if (isCefalometria) {
      const fetchDataProcess = async () => {
        try {
          for (const currentUser of user) {
            const { idClient } = currentUser;
            const processos = await Api.GetProcess(idClient);

            if (processos.isSuccess) {
              const processosAtualizados = [{ id: -1, name: "Todos" }, ...processos.data];

              const processosOrdenados = processosAtualizados.slice(1).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              });

              processosOrdenados.unshift(processosAtualizados[0]);

              setResultProcessos(processosOrdenados);
            }
          }

        } catch (error) {
          console.error(error);
        }


      };
      fetchDataProcess();

    }
  }, []);

  return (
    <PatientContext.Provider
      value={{
        patientList,
        setPatientList,
        filteredPatientList,
        setFilteredPatientList,
        loadingNextPage,
        setLoadingNextPage,
        lastPage,
        setLastPage,
        resetPatientLoading,
        reactivePatientsLoading,
        loadingButtonChecklist,
        setLoadingButtonCheckList,
        noPatientsCefx,
        setNoPatientsCefx,
        resultProcessos,
        setUpdateCefxList,
        updateCefxList,
        reorderedClinics,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
