import {
  useState,
  useCallback,
  useEffect,
  createElement,
  useContext,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import html2pdf from 'html2pdf.js'
import style from "./styles.module.css";
import { SelectModels } from "../../SelectModels";
import { SelectPatientAndSignature } from "../../SelectPatienteAndSignature";
import { AddItensToScreen } from "../../AddItensToScreen";
import { ButtonsContainer } from "../../ButtonsContainer";
import { ModalSendRequest } from "../../Modals/ModalSendRequest";
import { ModalSaveModel } from "../../Modals/ModalSaveModel";
import { TextEditor } from "../../TextEditor";

import axios, { AxiosInstance } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Quill from "quill";
import "quill/dist/quill.snow.css";

import { CircularProgress } from "@mui/material";

import Api from "../../../../helpers/Api";
import { getModel, getExamRequest } from "../../utils/apiConnection";
import { selectUser } from "../../../../store/slices/userSlice";
import { toPng } from "html-to-image";
import { ModalErrorOrSucessMessage } from "../../../../components/ModalErrorOrSucessMessage";
import { selectTempPatientsList } from "../../../../store/slices/tempPatientListSlice";
import { selectisClinicConnectionsComplete } from "../../../../store/slices/isClinicConnectionsCompleteSlice";
import { selectPatientsList } from "../../../../store/slices/patientListSlice";
import { PatientContext } from "../../../PatientList/PatientProvider";
import zIndex from "@mui/material/styles/zIndex";

export const Editor = () => {
  const [isModalSendRequestOpen, setModalSendRequestOpen] = useState(false);
  const [isModalSaveModelOpen, setModalSaveModelOpen] = useState(false);
  const [isModalErrorOrSucessMessage, setModalErrorOrSucessMessage] =
    useState(false);
  const [quill, setQuill] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [isPatientHTMLRendered, setPatientHTMLRendered] = useState(false);
  const [isHeaderActive, setHeaderActive] = useState(false);
  const [isFooterActive, setFooterActive] = useState(false);
  const [isAboveLimit, setAboveLimit] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [signature, setSignature] = useState(null);
  const [signatureImage, setSignatureImage] = useState<HTMLElement>(null);
  const [modelData, setModelData] = useState(null);
  const [examRequestData, setExamRequestData] = useState(null);
  const [wasModelUpdated, setModelUpdated] = useState(false);
  const [wasExamRequestUpdate, setExamRequestUpdate] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [response, setResponse] = useState("");

  const [logoClinic, setLogoClinic] = useState(null);
  const [addressClinic, setAddressClinic] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [idClientPatient, setIdClientPatient] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [controller, setController] = useState(null);
  const [patientsLoading, setPatientsLoading] = useState(false);
  const [patientLoading, setPatientLoading] = useState(false);
  const [modalType, setModalType] = useState("");

  const { user } = useSelector(selectUser);
  const { patients } = useSelector(selectPatientsList);
  const { tempPatients } = useSelector(selectTempPatientsList);
  const { isClinicConnectionsComplete } = useSelector(
    selectisClinicConnectionsComplete
  );
  const { id } = useParams();
  const location = useLocation();
  const apiKey = process.env.REACT_APP_EXAM_REQUEST_API_KEY;
  const idUser = user[0].idUser;
  const hashUser = user[0].hash;
  const { reorderedClinics } = useContext(PatientContext);

  class DividerBlot extends Quill.import("blots/embed") {
    static blotName = "divider";
    static className = "divider";
    static tagName = "hr";

    static create() {
      const node = super.create();
      return node;
    }
  }

  const handleOpenModalSendRequest = () => {
    setModalSendRequestOpen(true);
  };

  const handleCloseModalSendRequest = () => {
    setModalSendRequestOpen(false);
  };

  const handleOpenModalSaveModel = () => {
    setModalSaveModelOpen(true);
  };

  const handleCloseModalSaveModel = () => {
    setModalSaveModelOpen(false);
  };

  const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["image", "blockquote"],
    ["clean"],
    [{ background: [] }],
  ];

  const wrapperRef = useCallback((wrapper) => {
    if (wrapper === null) return;

    wrapper.innerHTML = "";
    const editor = document.createElement("div");
    wrapper.append(editor);

    const q = new Quill(editor, {
      theme: "snow",
      modules: { toolbar: TOOLBAR_OPTIONS },
      placeholder: 'Clique aqui para começar a digitar...',
    });

    Quill.register(DividerBlot, true);

    const localDocument = localStorage.getItem("recentDocument");
    if (localDocument) {
      q.root.innerHTML = localDocument;

      if (q.root.querySelector(".header") && !isHeaderActive) {
        setHeaderActive(true);
      }

      if (q.root.querySelector(".footer") && !isFooterActive) {
        setFooterActive(true);
      }

      if (q.root.querySelector(".patient-selected")) {
        setPatientHTMLRendered(true);
        replacePatientText();
      }

      setTimeout(() => removeExtraSpaces(q, 2, 2), 100);
    } else {
      q.clipboard.dangerouslyPasteHTML('<p><br><br><br><br><br><br><br><br><br><br></p>');
    }

    setQuill(q);

    const updateHeaderAndFooter = () => {
      const header = q.root.querySelector(".header");
      const footer = q.root.querySelector(".footer");

      if (!header && isHeaderActive) {
        const headerElement = document.createElement('div');
        headerElement.className = 'header';
        headerElement.innerHTML = `<h2>Header</h2>`;
        q.root.insertBefore(headerElement, q.root.firstChild);
      }

      if (!footer && isFooterActive) {
        const footerElement = document.createElement('div');
        footerElement.className = 'footer';
        footerElement.innerHTML = `<h2>Footer</h2>`;
        q.root.appendChild(footerElement);
      }
    };

    updateHeaderAndFooter();

    q.container.addEventListener("keydown", (event) => {
      if (event.key === "F3") {
        let cursorPosition = q.getSelection()?.index || 0;

        if (q.getText(cursorPosition - 3, 3) === "-ca") {
          q.deleteText(cursorPosition - 3, 3);
          q.insertText(cursorPosition - 3, "Área radiolúcida compatível com cárie. ");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (quill) {
      if (modelData !== null) {
        quill.root.innerHTML = modelData.html;
        if (quill.root.querySelector(".patient-selected")) {
          setPatientHTMLRendered(true);
          replacePatientText();

          if (quill.root.querySelector(".header") && !isHeaderActive) {
            setHeaderActive(true);
          }
          if (quill.root.querySelector(".footer") && !isFooterActive) {
            setFooterActive(true);
          }

          setTimeout(() => removeExtraSpaces(quill, 2, 1), 150);
        }
      }
      if (examRequestData !== null) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = examRequestData.html;
        const div = tempDiv.querySelector(".ql-editor");
        /*         quill.root.innerHTML = examRequestData.html; */
        quill.root.innerHTML = div.innerHTML;
        /* removeSpaceAboveHeader(); */
        setPatientHTMLRendered(true);
        if (quill.root.querySelector(".header") && !isHeaderActive) {
          setHeaderActive(true);
        }
        if (quill.root.querySelector(".footer") && !isFooterActive) {
          setFooterActive(true);
        }

        setTimeout(() => removeExtraSpaces(quill, 2, 1), 150);
      }
    }
  }, [quill, modelData, examRequestData, selectedPatient]);

  useEffect(() => {
    const loadParams = async () => {
      if (location.pathname.includes("editar-modelo")) {
        const modelData = await getModel(Number(id));
        if (modelData) {
          setModelData(modelData);
        }
      } else if (location.pathname.includes("editar-requisicao")) {
        const examRequestData = await getExamRequest(
          Number(id),
          hashUser,
          apiKey
        );
        if (examRequestData) {
          setExamRequestData(examRequestData);
          const clinic = user.find(
            (obj) => obj.idClient === examRequestData.idClient
          );
          if (clinic) {
            setSelectedClinic(clinic);
            localStorage.setItem("selectedClinic", JSON.stringify(clinic));
          }
          let patient;
          if (examRequestData.idPatient === 0) {
            patient = examRequestData;
          } else {
            patient = patientList.find(
              (obj) => obj.idPatient === examRequestData.idPatient
            );
          }


          if (patient) {
            setPatientLoading(false);
            setSelectedPatient(patient);
            localStorage.setItem("selectedPatient", JSON.stringify(patient));
          }
          setExamRequestUpdate(false);
        }
      }
    };

    loadParams();
  }, [
    quill,
    id,
    location,
    patientList,
    user,
    wasModelUpdated,
    wasExamRequestUpdate,
  ]);

  useEffect(() => {
    if (examRequestData) {
      setPatientLoading(true);
    }
  }, [examRequestData]);


  useEffect(() => {
    if (!quill || !signatureImage) return;

    const isElement = signatureImage instanceof Element;
    if (!isElement) {
      console.error("signatureImage não é um elemento válido:", signatureImage);
      return;
    }

    const footer = quill.root.querySelector(".footer");
    const insertBeforeNode = footer ? footer : quill.root.lastChild;

    if (insertBeforeNode instanceof Node) {
      quill.root.insertBefore(signatureImage, insertBeforeNode);
    } else {
      console.error(
        "O ponto de inserção não é um nó válido:",
        insertBeforeNode
      );
    }
  }, [quill, signatureImage]);

  useEffect(() => {
    if (selectedClinic) {
      const imgTag = document.createElement("img");
      imgTag.src = `https://cdt.com.br/cli/p/getImagem.aspx?cmp=${selectedClinic.idClient === null ? 3889 : selectedClinic.idClient
        }&nm=2`;
      imgTag.height = 28;
      imgTag.width = 73;
      setLogoClinic(imgTag);

      const addressClinic = `${selectedClinic.address} ${selectedClinic.neighborhood} - ${selectedClinic.city}`;
      setAddressClinic(addressClinic);
    }
  }, [
    selectedPatient,
    clinics,
    idClientPatient,
    selectedClinic,
    isHeaderActive,
    isFooterActive,
  ]);


  useEffect(() => {
    if (!quill) return;

    const header = quill.root.querySelector(".header");
    const footer = quill.root.querySelector(".footer");

    const updateHeader = () => {
      if (header) {
        header.innerHTML = "";
        if (logoClinic) {
          const headerLogo = logoClinic.cloneNode(true);
          header.append(headerLogo);
        }
      }
    };

    const updateFooter = () => {
      if (footer) {
        footer.innerHTML = "";
        if (addressClinic) {
          footer.append(addressClinic);
        }
        if (logoClinic) {
          const footerLogo = logoClinic.cloneNode(true);
          footer.append(footerLogo);
        }
        footer.style.gap = "16px";
      }
    };

    updateHeader();
    updateFooter();

    return () => {
      if (header) header.innerHTML = "";
      if (footer) footer.innerHTML = "";
    };
  }, [quill, logoClinic, addressClinic, selectedClinic]);

  useEffect(() => {
    const fetchClinics = async () => {
      try {
        const response = await Api.getClinics(idUser);
        if (response.isSuccess) {
          setClinics(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchClinics();
  }, []);

  useEffect(() => {
    patientHTMLRenderization();
  }, [quill, selectedPatient, isPatientHTMLRendered]);

  const patientHTMLRenderization = () => {
    if (!quill || !selectedPatient || isPatientHTMLRendered || examRequestData) return;

    const header = quill.root.querySelector(".header");

    const patientHTML = `
      <div class="patient-info-container">
        <br><br><br><br> <!-- Adiciona quebras de linha -->
        <h2 class="ql-align-center patient-selected">
          Ao paciente ${selectedPatient?.paciente || ""}
        </h2>
        <p class="ql-align-center patient-selected">
          Sexo ${selectedPatient?.sexo}, ${selectedPatient?.idade.split("a ")[0] || ""} anos 
          ${selectedPatient.idade.split(" ")[1] ? `e ${selectedPatient?.idade.split(" ")[1].split("m")[0] || ""} meses, ` : ""} 
          com e-mail: ${selectedPatient?.email || ""}
        </p>
      </div>
    `;

    if (header !== null) {
      header.remove();
      quill.clipboard.dangerouslyPasteHTML(0, patientHTML);
      quill.root.insertBefore(header, quill.root.firstChild);
      removeOldSpacesFromHTMLPatient();
      setPatientHTMLRendered(true);
      return;
    }

    quill.clipboard.dangerouslyPasteHTML(0, patientHTML);
    removeOldSpacesFromHTMLPatient();
    setPatientHTMLRendered(true);
  };

  useEffect(() => {
    patientHTMLRemotion();
  }, [quill, selectedPatient, examRequestData, selectedClinic]);

  const patientHTMLRemotion = () => {
    if (!quill) return;
    if (quill.root.querySelectorAll(".patient-selected") && !selectedPatient) {
      quill.root.querySelectorAll(".patient-selected").forEach((element) => {
        element.remove();
      });
      setPatientHTMLRendered(false);
    }
  };

  const replacePatientText = () => {
    if (!quill) return;
    const patient = selectedPatient;
    if (patient) {
      const patientHTMLArray: HTMLElement[] = Array.from(
        quill.root.querySelectorAll(".patient-selected")
      );
      const h1s = patientHTMLArray.filter(
        (element: HTMLElement) => element.tagName === "H1"
      );
      if (h1s.length === 2 && h1s[0].textContent && h1s[1].textContent) {
        h1s[0].innerHTML = "<br>";
      }
      patientHTMLArray.forEach((element: HTMLElement) => {
        if (
          element.tagName === "H1" &&
          !element.querySelector("br") &&
          element.textContent
        ) {
          element.textContent = `Ao paciente ${patient.paciente}`;
        }

        if (
          element.tagName === "P" &&
          !element.querySelector("br") &&
          element.textContent
        ) {
          element.textContent = `Sexo ${patient?.sexo || ""}, ${patient?.idade.split("a ")[0] || ""
            } anos e ${selectedPatient.idade.split(" ")[1]
              ? selectedPatient?.idade.split(" ")[1].split("m")[0] +
              " meses," || ""
              : ""
            } com e-mail: ${patient?.email || ""}`;
        } else {
          patientHTMLRemotion();
        }
      });
    }
  };

  // const captureAndDownload = (): Promise<void> => {
  //   return new Promise<void>((resolve, reject) => {
  //     if (quill) {
  //       const content = quill.root;

  //       // Define as dimensões da página A4 em pixels para 96 DPI (794x1123)
  //       const a4WidthPx = 794;
  //       const a4HeightPx = 1123;

  //       const invisibleContainer = document.createElement('div');
  //       invisibleContainer.style.position = 'absolute';
  //       invisibleContainer.style.top = '-9999px';
  //       invisibleContainer.style.left = '-9999px';
  //       invisibleContainer.style.width = `${a4WidthPx}px`;
  //       invisibleContainer.style.height = `${a4HeightPx}px`;
  //       invisibleContainer.style.overflow = 'hidden';

  //       const contentClone = content.cloneNode(true) as HTMLElement;

  //       contentClone.style.width = `${a4WidthPx}px`;
  //       contentClone.style.height = `${a4HeightPx}px`;
  //       contentClone.style.overflow = 'hidden';
  //       contentClone.style.boxSizing = 'border-box';

  //       const headerClone = contentClone.querySelector('.header') as HTMLElement;
  //       const footerClone = contentClone.querySelector('.footer') as HTMLElement;

  //       if (headerClone) {
  //         headerClone.style.width = '100%';
  //         headerClone.style.margin = '0';
  //         headerClone.style.padding = '0';
  //         headerClone.style.display = 'flex';
  //         headerClone.style.alignItems = 'center';
  //         headerClone.style.justifyContent = 'center';
  //         headerClone.style.boxSizing = 'border-box';
  //         headerClone.style.position = 'absolute';
  //         headerClone.style.left = '0';
  //       }

  //       if (footerClone) {
  //         footerClone.style.width = '100%';
  //         footerClone.style.margin = '0';
  //         footerClone.style.padding = '0';
  //         footerClone.style.display = 'flex';
  //         footerClone.style.alignItems = 'center';
  //         footerClone.style.justifyContent = 'center';
  //         footerClone.style.boxSizing = 'border-box';
  //         footerClone.style.position = 'absolute';
  //         footerClone.style.bottom = '0';
  //         footerClone.style.left = '0';
  //       }


  //       invisibleContainer.appendChild(contentClone);
  //       document.body.appendChild(invisibleContainer);

  //       setTimeout(() => {
  //         toPng(contentClone, { width: a4WidthPx, height: a4HeightPx })
  //           .then((dataUrl) => {

  //             const pdf = new jsPDF('p', 'pt', 'a4');
  //             const pdfWidth = pdf.internal.pageSize.getWidth();
  //             const pdfHeight = pdf.internal.pageSize.getHeight();

  //             pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);


  //             const blob = pdf.output('blob');
  //             saveAs(blob, 'requisicao_de_exames.pdf');

  //             resolve();
  //           })
  //           .catch((error) => {
  //             console.error('Erro ao capturar o conteúdo como PNG:', error);
  //             reject(error);
  //           })
  //           .finally(() => {

  //             document.body.removeChild(invisibleContainer);
  //           });
  //       }, 300);
  //     } else {
  //       reject(new Error('Quill editor não encontrado.'));
  //     }
  //   });
  // };

  const captureAndDownload = () => {
    if (quill) {
      const content = quill.root;
      const footer = content.querySelector(".footer");

      footerRealigment(1099);

      /*       if (footer) {
        let desiredEditorHeight = 1099;

        if (
          getTotalHeight() < desiredEditorHeight &&
          desiredEditorHeight - getTotalHeight() <= 1099
        ) {
          footer.style.marginTop = `${
            desiredEditorHeight - getTotalHeight()
          }px`;
        }
      } */

      /* content.style.overflow = "hidden";
      content.style.border = "none"; */

      toPng(content).then((dataUrl) => {
        var pdf = new jsPDF("p", "mm", "a4");
        var pdfWidth = pdf.internal.pageSize.getWidth();
        var pdfHeight = pdf.internal.pageSize.getHeight();

        var width = pdfWidth;
        var height = pdfHeight;

        pdf.addImage(dataUrl, "PNG", 0, 0, width, height);

        const blob = pdf.output("blob");
        saveAs(blob, "documento_quill.pdf");

        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onloadend = function () {
          if (typeof reader.result === "string") {
            throw new Error("Unexpected result reading file");
          }

          const arrayBuffer = reader.result;
          const array = new Uint8Array(arrayBuffer);
          const pdfArray = Array.from(array);
        };
        reader.onerror = function () {
          console.error("An error occurred reading the file:", reader.error);
        };

        reader.onabort = function () {
          console.error("File read cancelled");
        };
      });

      /* const htmlContentWithInlineStyles = convertClassToInlineStyles(quill.root.innerHTML);
      console.log(htmlContentWithInlineStyles) */

      footer.style.marginTop = "0px";
      content.style.overflow = "auto";
      content.style.border =
        "1px solid var(--neutral-colors-gray-gray-200, #E4E4E4)";
      content.style.padding = "0px 0px";
    }
  };
  
  useEffect(() => {
    if (!quill) return;
    quill.on("text-change", function (delta, oldDelta, source) {
      if (examRequestData) {
        removeSpaceAboveHeader();
      }
      /* setAboveLimit(false); */
      localStorage.setItem("recentDocument", quill.root.innerHTML);
    });
  }, [quill, examRequestData]);

  const removeSpaceAboveHeader = () => {
    if (!quill) return;
    const content = quill.root;
    const pElements = Array.from(content.querySelectorAll("p"));
    pElements.forEach((p: Element) => {
      if (p.classList.contains("header")) {
        if (p.previousElementSibling) {
          p.parentNode.removeChild(p.previousElementSibling);
        }
      }

      if (p.classList.contains("footer")) {
        if (p.nextElementSibling) {
          p.parentNode.removeChild(p.nextElementSibling);
        }
      }
    });
  };

  useEffect(() => {
    const limitQuillHeight = () => {
      if (!quill) return;
      let limit = 1099;
      quill.on("text-change", function (delta, oldDelta, source) {
        let editorHeight = getTotalHeight();
        if (editorHeight > limit) {
          /* quill.history.undo(); */
          setAboveLimit(true);
        } else {
          setAboveLimit(false);
        }
      });
    };
    setTimeout(() => {
      limitQuillHeight();
    }, 120);
  }, [quill]);

  /*   useEffect(() => {
    const getSignature = async () => {
      const signatureData = await getExamRequestPart(3);
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = signatureData.html;
      setSignature(tempDiv.childNodes[0]);
    };
    getSignature();
  }, []); */
  /* 
  const postExamRequestPart = async() => {
    try {
      const footerHTML = document.createElement('p');
      footerHTML.textContent = `Av. Gen. Ramiro de Noronha, 237 Jardim Cuiabá ${'-'} Cuiabá ${'-'} MT CEP: 78043-272 (65) 3623-2233`;
      footerHTML.className = 'footer';
      const img = document.createElement('img');
      img.src = LogoMaikeBueno;
      footerHTML.append(img);
      const signatureHTML = document.createElement('img');
      signatureHTML.src = Signature;
      signatureHTML.alt = 'assinatura';
      const signatureData = {
        id: 3,
        active: true,
        html: signatureHTML.outerHTML,
        idUser: user.id,
        codeType: 3
      }
      const response = await Api.PostExamRequestPart(signatureData, apiKey);
      if(response.isSuccess) {
        console.log(response)
      }
      console.log(signatureData);
    } catch(error) {
      console.error(error);
    }
  }; */

  const getTotalHeight = () => {
    let totalHeight = 0;
    const quillChildren = Array.from(quill.root.children);
    quillChildren.forEach((element: HTMLElement) => {
      let heightValueArray = window
        .getComputedStyle(element, null)
        .getPropertyValue("height")
        .match(/\d+/);
      if (heightValueArray) {
        let heightValue = Number(heightValueArray[0]);
        totalHeight += heightValue;
      }
    });
    return totalHeight;
  };

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = "http://cdt.com.br/so/azradiologia/api/mobile";

      const api: AxiosInstance = axios.create({
        baseURL: apiUrl,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        responseType: "json",
      });

      const requestData = {
        chave: "-ca",
        Usuario: 1,
      };

      await api
        .post("/Laudo/postAutoTexto", requestData)
        .then((response) => { })
        .catch((error) => {
          console.error("Erro ao chamar a API:", error);
        });
    };
    /* fetchData(); */
  }, []);

  useEffect(() => {
    if (!quill) return;
    quill.on("selection-change", function (range, oldRange, source) {
      if (range) {
        setCursorPosition(range.index);
      }
    });
  }, [quill]);

  const insertDivision = () => {
    if (!quill) return;

    if (cursorPosition) {
      const divider = document.createElement("hr");
      quill.clipboard.dangerouslyPasteHTML(cursorPosition, divider.outerHTML);
      quill.setSelection(cursorPosition + 1, 0);
    }
  };

  const insertSignature = (props) => {
    if (!quill) return;

    const signature = props.image;
    if (cursorPosition) {
      quill.clipboard.dangerouslyPasteHTML(cursorPosition, signature.outerHTML);
      quill.setSelection(cursorPosition + 1, 0);
    }
  };

  const removeExtraSpaces = (q, topSpaces: number, bottomSpaces: number) => {
    // if (!q) return;

    // const quillRoot = q.root as HTMLElement;

    // const pElements = Array.from(quillRoot.querySelectorAll("p"));
    // pElements.forEach((p) => {
    //   if (p.querySelector("hr")) {
    //     for (let i = 0; i < topSpaces; i++) {
    //       if (
    //         p.previousElementSibling &&
    //         p.previousElementSibling.innerHTML === "<br>"
    //       ) {
    //         p.parentNode.removeChild(p.previousElementSibling);
    //       }
    //     }

    //     for (let i = 0; i < bottomSpaces; i++) {
    //       if (
    //         p.nextElementSibling &&
    //         p.nextElementSibling.innerHTML === "<br>"
    //       ) {
    //         p.parentNode.removeChild(p.nextElementSibling);
    //       }
    //     }
    //   }
    // });
  };

  const removeOldSpacesFromHTMLPatient = () => {
    const quillChildren = Array.from(quill.root.children);
    quillChildren.forEach((element: HTMLElement) => {
      let heightValueArray = window
        .getComputedStyle(element, null)
        .getPropertyValue("height")
        .match(/\d+/);
      if (heightValueArray) {
        let heightValue = Number(heightValueArray[0]);
        if (
          element.tagName.toUpperCase() === "P" &&
          heightValue === 36 &&
          element.innerHTML.trim() === ""
        ) {
          element.remove();
        }
      }
    });
  };

  const footerRealigment = (
    desiredEditorHeight: number,
    footerMargin?: number | string
  ) => {
    const content = quill.root;
    const footer = content.querySelector(".footer");

    if (footer) {
      if (footer.querySelector("img") && footerMargin) {
        footer.querySelector("img").style.marginRight = footerMargin;
      }

      let currentHeight = getTotalHeight();
      /*  let desiredEditorHeight = 1099; */ //1188
      let lineHeight = 1.42 * 13;

      if (
        currentHeight < desiredEditorHeight &&
        currentHeight + lineHeight < desiredEditorHeight
      ) {
        let spacesNeeded = Math.floor(
          (desiredEditorHeight - currentHeight) / lineHeight
        );
        let totalSpacesFilled = 0;

        for (let i = 0; i < spacesNeeded; i++) {
          let p = document.createElement("p");
          p.innerHTML = "<br>";
          totalSpacesFilled += lineHeight;

          quill.root.insertBefore(p, footer);
        }
      }
    }

    content.style.overflow = "hidden";
    content.style.border = "none";
  };

  const restartStyles = () => {
    quill.history.undo();
    const content = quill.root;
    const footer = content.querySelector(".footer");

    if (footer) {
      if (footer.querySelector("img")) {
        footer.querySelector("img").style.marginRight = "0px";
      }
    }

    content.style.overflow = "auto";
    content.style.border =
      "1px solid var(--neutral-colors-gray-gray-200, #E4E4E4)";
    content.style.padding = "0px 0px";
  };

  useEffect(() => {
    const headerRenderization = () => {
      if (!quill) return;
      const header = quill.root.querySelector('.header');

      if (isHeaderActive && !header) {
        const headerHTML = document.createElement('p');
        headerHTML.className = 'header';
        headerHTML.style.height = '60px';
        headerHTML.style.width = '100%';
        headerHTML.style.backgroundColor = '#f8f8f8';
        headerHTML.style.display = 'flex';
        headerHTML.style.alignItems = 'center';
        headerHTML.style.justifyContent = 'center';
        headerHTML.style.position = 'absolute';
        headerHTML.style.top = '0';
        headerHTML.style.zIndex = '0';

        quill.root.insertBefore(headerHTML, quill.root.firstChild);
      } else if (!isHeaderActive && header) {
        header.remove();
      }
    };

    const footerRenderization = () => {
      if (!quill) return;
      const footer = quill.root.querySelector('.footer');

      if (isFooterActive && !footer) {
        const footerHTML = document.createElement('p');
        footerHTML.className = 'footer';
        footerHTML.style.height = '60px';
        footerHTML.style.width = '100%';
        footerHTML.style.backgroundColor = '#031b4e';
        footerHTML.style.display = 'flex';
        footerHTML.style.alignItems = 'center';
        footerHTML.style.justifyContent = 'center';
        footerHTML.style.position = 'absolute';
        footerHTML.style.bottom = '0';
        footerHTML.style.zIndex = '10';
        footerHTML.style.margin = '0';
        footerHTML.style.padding = '0';
        quill.root.append(footerHTML);
      } else if (!isFooterActive && footer) {
        footer.remove();
      }
    };

    headerRenderization();
    footerRenderization();

  }, [quill, isHeaderActive, isFooterActive]);

  useEffect(() => {
    setPatientsLoading(true);
    const fetchPatients = async () => {
      try {
        if (selectedClinic) {
          const newController = new AbortController();
          setController(newController);

          let allPatientsData = [];
          let currentPage = 0;
          let totalPages = 0;

          do {
            const response = await Api.GetPatientList(
              {
                idClient: selectedClinic.idClient,
                hash: selectedClinic.hash,
                page: currentPage,
              },
              "",
              newController.signal
            );

            if (response.data && response.data.patients) {
              response.data.patients.map((patient) => {
                patient["label"] = patient.paciente;
                patient["value"] = patient.paciente;
              });

              allPatientsData = [...allPatientsData, ...response.data.patients];
              totalPages = response.data.totalPages;
              setPatientList(allPatientsData);
            }

            currentPage++;
          } while (currentPage < totalPages);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          throw new Error("Request canceled");
        }
      } finally {
        setPatientsLoading(false);
      }
    };

    if (controller) {
      controller.abort();
    }

    setPatientList([]);
    if (selectedClinic) {
      const localClinicConnection = JSON.parse(
        localStorage.getItem("currentClinicConnection")
      );
      const indexSelectedClinic = reorderedClinics.current.findIndex(
        (clinic) => clinic.idClient === selectedClinic.idClient
      );

      if (
        isClinicConnectionsComplete === true ||
        (localClinicConnection &&
          localClinicConnection.clinicIndex > indexSelectedClinic &&
          patients &&
          patients.length > 0)
      ) {
        let allPatients = patients;

        if (tempPatients.length > 0) {
          const allTempPatients = [].concat(...tempPatients);
          allPatients = [...patients, ...allTempPatients];
        }

        const filteredPatients = allPatients.filter(
          (patient) => patient.idClient === selectedClinic.idClient
        );
        const updatedPatients = filteredPatients.map((patient) => ({
          label: patient.paciente,
          value: patient.paciente,
          ...patient,
        }));

        setPatientList(updatedPatients);
        setPatientLoading(false);
        setPatientsLoading(false);
      } else {
        fetchPatients();
      }
    }

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [selectedClinic, reorderedClinics]);


  return (
    <section
      className={style.containerExamsRequest}
      style={
        patientLoading ? { position: "relative" } : { visibility: "visible" }
      }
    >
      <article
        className={style.containerExam}
        style={
          patientLoading ? { visibility: "hidden" } : { visibility: "visible" }
        }
      >
        {isAboveLimit && (
          <p className={style.errorText}>
            Não há espaço disponível no editor para adicionar linhas. Apague
            espaços desnecessários se deseja adicionar mais conteúdo.
          </p>
        )}
        <TextEditor wrapperRef={wrapperRef} />
      </article>

      {patientLoading && (
        <div
          style={{
            display: "grid",
            position: "absolute",
            top: "30%",
            left: "50%",
            placeContent: "center",
          }}
        >
          <CircularProgress size={50} sx={{ color: "#006E5A" }} />
        </div>
      )}

      <div className={style.wrapperComponentsEditor}>
        <article
          className={style.containerModel}
          style={
            patientLoading ? { visibility: "hidden" } : { visibility: "visible" }
          }
        >
          <SelectModels
            quill={quill}
            idUser={idUser}
            removeExtraSpaces={removeExtraSpaces}
            replacePatientText={replacePatientText}
            selectedPatient={selectedPatient}
            wasModelUpdated={wasModelUpdated}
            setModelUpdated={setModelUpdated}
          />
        </article>

        <article
          className={style.containerInputs}
          style={
            patientLoading ? { visibility: "hidden" } : { visibility: "visible" }
          }
        >
          <SelectPatientAndSignature
            isForEdit={examRequestData ? true : false}
            selectedPatient={selectedPatient}
            setSelectedPatient={setSelectedPatient}
            signature={signature ? signature : ""}
            insertSignature={insertSignature}
            setSignatureImage={setSignatureImage}
            clinics={clinics}
            selectedClinic={selectedClinic}
            setSelectedClinic={setSelectedClinic}
            patientList={patientList}
            patientsLoading={patientsLoading}
            setPatientsLoading={setPatientsLoading}
            setPatientList={setPatientList}
            controller={controller}
          />
        </article>

        <article
          className={style.containerStyle}
          style={
            patientLoading ? { visibility: "hidden" } : { visibility: "visible" }
          }
        >
          <AddItensToScreen
            isHeaderActive={isHeaderActive}
            setHeaderActive={setHeaderActive}
            isFooterActive={isFooterActive}
            setFooterActive={setFooterActive}
            insertDivision={insertDivision}
          />
        </article>

        <article
          className={style.containerFooter}
          style={
            patientLoading ? { visibility: "hidden" } : { visibility: "visible" }
          }
        >
          <ButtonsContainer
            selectedPatient={selectedPatient}
            handleOpenModalSendRequest={handleOpenModalSendRequest}
            handleDownload={captureAndDownload}
            handleOpenModalSaveModel={handleOpenModalSaveModel}
            modelData={modelData}
            examRequestData={examRequestData}
            selectedClinic={selectedClinic}
            setModalErrorOrSucessMessage={setModalErrorOrSucessMessage}
            setModalType={setModalType}
            isAboveLimit={isAboveLimit}
          />
        </article>
      </div>

      {isModalSendRequestOpen && (
        <ModalSendRequest
          isOpen={isModalSendRequestOpen}
          onRequestClose={() => {
            handleCloseModalSendRequest();
            restartStyles();
          }}
          apiKey={apiKey}
          hashClinic={selectedClinic.hash}
          content={quill ? quill.root.innerHTML : ""}
          idUser={idUser}
          idClient={selectedClinic.idClient}
          idPatient={selectedPatient?.idPaciente}

          examRequestToEdit={examRequestData}
          setExamRequestUpdate={setExamRequestUpdate}
          setModalErrorOrSucessMessage={setModalErrorOrSucessMessage}
          setModalType={setModalType}
          restartStyles={restartStyles}
        />
      )}

      {isModalSaveModelOpen && (
        <ModalSaveModel
          isOpen={isModalSaveModelOpen}
          onRequestClose={handleCloseModalSaveModel}
          content={quill ? quill.root.innerHTML : ""}
          idUser={idUser}
          modelData={modelData}
          setModelUpdated={setModelUpdated}
        />
      )}

      {isModalErrorOrSucessMessage && (
        <ModalErrorOrSucessMessage
          type={modalType}
          isOpen={isModalErrorOrSucessMessage}
          onRequestClose={() => setModalErrorOrSucessMessage(false)}
          handleClose={() => setModalErrorOrSucessMessage(false)}
        />
      )}
    </section>
  );
};
