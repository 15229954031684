import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "@cdt-ui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import * as yup from "yup";
import $ from "jquery";

import { Link } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { Checkbox } from "../../components/Form/Checkbox";
import { Input } from "../../components/Form/Input";
import { BoxVideoInstitutional } from "../../components/BoxVideoInstitutional";
import { Footer } from "../../components/Footer";

import { ModalRecoverPassword } from "../../components/ModalRecoverPassword";
import { ModalErrorOrSucessMessage } from "../../components/ModalErrorOrSucessMessage";

import styles from "./styles.module.css";
import Api from "../../helpers/Api";
import axios from "axios";
import { selectUser } from "../../store/slices/userSlice";
import { toast } from "react-hot-toast";
import { BoxVideoInstitutionalResponsive } from "./BoxVideoInstitutional";
import { BoxVideoInstitutionalTablet } from "./BoxVideoInstitutionalTablet";
import Cookies from "js-cookie";
import { clearPatients, setpatients } from "../../store/slices/patientListSlice";
import { clearTempPatients } from "../../store/slices/tempPatientListSlice";
import {
  resetIsClinicConnectionsComplete,
  setIsClinicConnectionsComplete
} from "../../store/slices/isClinicConnectionsCompleteSlice";
import { setIsCefalometria } from "../../store/slices/isCefalometriaSlice";
import { setResearchPatient } from "../../store/slices/researchPatientSlice";
import { clearPatientCefxAConcluir } from "../../store/slices/patientCefxAConcluirSlice";
import { clearPatientCefxConcluidos } from "../../store/slices/patientCefxConcluidosSlice";
import { clearPatientCefxEmAndamento } from "../../store/slices/patientCefxEmAndamentoSlice";
import { setProcesso } from "../../store/slices/processoSlice";
import { setToConcludeCefx } from "../../store/slices/toConcludeCefxSlice";
import { clearAllConcludesLoaded } from "../../store/slices/loadedConcludesSlices";
import { clearPatientWithImagens } from "../../store/slices/patientsWithImagesSlice";
import { setindicator } from "../../store/slices/indicatorListSlice";
import { setClinic } from "../../store/slices/clinicSlice";
import PwaInstallPopupIOS from 'react-pwa-install-ios';
import { setShared } from "../../store/slices/sharedSlice";

export function Login({ ...props }) {
  const navigate = useNavigate();

  const { user } = useSelector(selectUser);

  const [isChecked, setChecked] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalErrorOrSuccessOpen, setModalErrorOrSuccessOpen] =
    useState(false);
  const [modalType, setModalType] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);
  const [customDescription, setCustomDescription] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qParam = queryParams.get("q") == null ? null : parseInt(queryParams.get("q"), 16) + "";
  const idClient = queryParams.get("cli") || qParam;
  const cefalometria = queryParams.get("cefalometria");
  const token = queryParams.get("t");
  const seqAtend = queryParams.get("seqAtend");

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };
  const handleOPenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalErrorOrSuccess = () => {
    setModalErrorOrSuccessOpen(true);
  };

  const handleCloseModalErrorOrSuccess = () => {
    setModalErrorOrSuccessOpen(false);
  };

  const clearStore = () => {
    dispatch(clearPatients());
    dispatch(clearTempPatients());
    dispatch(resetIsClinicConnectionsComplete());
    dispatch(clearPatientWithImagens());
    dispatch(setResearchPatient(false));
    setIsClinicConnectionsComplete(false);
    dispatch(setShared([]));

    dispatch(clearPatientCefxAConcluir());
    dispatch(clearPatientCefxConcluidos());
    dispatch(clearPatientCefxEmAndamento());

    dispatch(setProcesso(-2));
    dispatch(setToConcludeCefx(0));
    dispatch(clearAllConcludesLoaded());

    localStorage.setItem("currentClinicConnection", null);
    localStorage.setItem("fatestClinicIndex", null);
    localStorage.setItem("currentPageNumber", null);
    localStorage.setItem("patientFilters", null);
    localStorage.setItem("toConclude", "0");
  };

  const handleSubmit = async (values: FormikValues) => {
    clearStore();
    sessionStorage.clear();

    const { nomeDeusuario, password } = values;

    const trimmedValues = {
      logon: nomeDeusuario.trim(),
      password,
      idClient,
    };

    if (cefalometria == "s" || cefalometria == "S") {
      dispatch(setIsCefalometria(true));
    } else if (!cefalometria) {
      dispatch(setIsCefalometria(false));
    }

    try {
      const result = await Api.SignIn(trimmedValues);
      if (result.isSuccess === true) {
        localStorage.setItem("idClientLogin", idClient);
        if (result.data.length === 0) {
          sessionStorage.setItem("logon", nomeDeusuario);
          sessionStorage.setItem("password", password);
          navigate("/NewClinic");
        } else {
          for (const item of result.data) {
            const clienteId = item.idClient;

            const getIP = await Api.GetIP(clienteId);

            item.ip = getIP;
          }

          if (isChecked === true) {
            Cookies.set("userMain", JSON.stringify(result.data), {
              expires: 30,
            });
          } else if (isChecked === false) {
            Cookies.remove("userMain");
          }

          dispatch(setUser(result.data));

          if (result.data[0].roles === "AUTENTICADO" || result.data[0].roles === "VINCULADO") {
            navigate("dashboard/lista-pacientes");
            //timeout para funcionar o pwa (se caso o sistema atualizar no login e não logar, provavel que seja esse timeout)
            // setTimeout(function () {
            //   window.location.reload();
            // }, 500);

          } else if (result.data[0].hash.includes("PC") || result.data[0].hash.includes("FN") || result.data[0].hash.includes("CV")) {
            navigate("dashboard/lista-pacientes");
          } else if (result.data[0].roles === "NAOAUTENTICADO") {
            navigate("/Cadastro");
          } else {
            navigate("/Cadastro");
          }
        }
      } else {
        setErrorLogin(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast("Ocorreu um erro!", {
          position: "top-right",
          style: {
            background: "#c80000",
            color: "#fff",
          },
        });
        setErrorLogin(true);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    const userValue = Cookies.get("userMain");

    if (userValue && !user) {
      const parsedUser = JSON.parse(userValue);
      dispatch(setUser(parsedUser));
      navigate("/dashboard/lista-pacientes");
      return;
    }

    if (user && !token) {
      navigate("/dashboard/lista-pacientes");
    }
  }, []);

  const [isInWebApps, setIsInWebApps] = useState(false);


  // useEffect(() => {
  //   let deferredPrompt;
  //   window.addEventListener("beforeinstallprompt", (e) => {
  //     e.preventDefault();
  //     deferredPrompt = e;
  //     $("#installModal").show();
  //   });

  //   $("#installButton").on("click", () => {
  //     if (deferredPrompt) {
  //       console.log("O usuário aceitou a instalação");
  //       deferredPrompt.prompt();
  //       deferredPrompt.userChoice.then((choiceResult) => {
  //         if (choiceResult.outcome === "accepted") {
  //           console.log("O usuário aceitou a instalação");
  //         } else {
  //           console.log("O usuário recusou a instalação");
  //         }
  //         deferredPrompt = null;
  //         $("#installModal").hide();
  //       });
  //     }
  //   });

  // }, []);
  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent;
  //   const isInWebApp = window.matchMedia('(display-mode: standalone)').matches;
  //   setIsInWebApps(isInWebApp || /iPhone|iPod|iPad/.test(userAgent));
  //   if (isInWebApps) {
  //     $("#installModal").hide();
  //     $(".container_createYourAccounts").addClass("hidden");
  //   }

  // }, [])


  useEffect(() => {
    if (user) {
      if (user[0].idClient !== idClient) {
        dispatch(setUser(false));
        dispatch(setIsCefalometria(false));
        dispatch(clearPatientWithImagens());
        dispatch(setpatients([]));
        dispatch(clearPatients());
        dispatch(setindicator(false));
        dispatch(setClinic(false));
        dispatch(setResearchPatient(false));
        Cookies.remove("userMain");
        navigate(`/?cli=${idClient}`);
      }
    }
  }, []);

  return (
    <Layout>
      <PwaInstallPopupIOS>
        <div

          style={{
            padding: '15px 30px',
            backgroundColor: 'blue',
            color: 'white',
            textAlign: 'center',
          }}
        >
          Hey ! I am the PwaInstallPopupIOS component.
          without the 'force=true' props, I will display only on iOS device,
          not in standalone mode.
        </div>
      </PwaInstallPopupIOS>
      <div className={styles.login}>
        <div className={styles.wrapper}>
          <div className={styles.wrapper_form}>
            <div className={styles.BoxVideoInstitutionalMobile}>
              <BoxVideoInstitutionalResponsive />
            </div>
            <div className={styles.container_form}>
              {/* <Logo /> */}

              <img
                className={styles.image}
                src={`https://cdt.com.br/cli/p/getImagem.aspx?cmp=${idClient == null ? 3889 : idClient
                  }&nm=2`}
              />

              <div className={styles.videoTablet}>
                <BoxVideoInstitutionalTablet></BoxVideoInstitutionalTablet>
              </div>

              <div className={styles.titleBox}>
                Faça login para acessar a plataforma
              </div>

              <Formik
                initialValues={{
                  nomeDeusuario: "",
                  password: "",
                }}
                onSubmit={(values) => handleSubmit(values)}
                validateOnChange={false}
                validateOnBlur={false}

              // validationSchema={loginValidationSchema}
              >
                {({ errors, isSubmitting, values }: any) => (
                  <Form className={styles.container_Form} autoComplete="off">
                    <p className={styles.container_inputs_title}>
                      Preencha os campos abaixo para acessar
                    </p>
                    <div className={styles.container_inputs}>
                      <Input
                        name="nomeDeusuario"
                        type="text"
                        title="Usuário"
                        error={errors.nomeDeusuario}
                        autoComplete="nope"
                      />
                      <div>
                        <Input
                          name="password"
                          type="password"
                          title="Senha"
                          error={errors.password}
                          autoComplete="new-password"
                        />

                        <div
                          className={styles.container_recoverPassword}
                          onClick={handleOPenModal}
                        >
                          <span>Esqueceu a senha?</span>
                        </div>

                        <div className={styles.containerCheckbox}>
                          <Checkbox
                            isChecked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                    </div>
                    {errorLogin && (
                      <div
                        className={`${styles.containerError}`}
                      >
                        <div className={styles.containerErroIcon}>
                          <ExclamationCircleIcon width={22} color="#C80000" />
                        </div>
                        <p>
                          Dados inválidos. Confira o e-mail e senha e tente
                          novamente.
                        </p>
                      </div>
                    )}

                    <div className={styles.container_buttons}>
                      <Button
                        className={styles.container_responsive_button}
                        variant="greenDark"
                        size="full"
                        type="submit"
                        disabled={
                          values.user === "" ||
                          values.password === "" ||
                          isSubmitting
                        }
                      >
                        {isSubmitting ? (
                          <div className={styles.container_buttons_loading}>
                            <div className={styles.loading_spinne}></div>
                          </div>
                        ) : (
                          "Entrar"
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className={styles.container_createYourAccount}>
                <span>Ainda não é cadastrado? </span>
                <Link to="/Cadastro">Cadastre-se.</Link>
              </div>

            </div>
          </div>

          <div className={styles.BoxVideoInstitutional}>
            <BoxVideoInstitutional />
          </div>
        </div>
        <div className={styles.BoxFooter}>
          {" "}
          <Footer backgroundColorTrue />
        </div>
        <div className={styles.BoxFooterrespnsive}>
          {" "}
          <Footer />
        </div>

        {isModalOpen && (
          <ModalRecoverPassword
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            handleOpenModalErrorOrSuccess={handleOpenModalErrorOrSuccess}
            setModalType={setModalType}
            setCustomDescription={setCustomDescription}
          />
        )}

        {isModalErrorOrSuccessOpen && (
          <ModalErrorOrSucessMessage
            type={modalType}
            handleClose={() => {
              handleCloseModalErrorOrSuccess();
              handleOPenModal();
            }}
            isOpen={isModalErrorOrSuccessOpen}
            onRequestClose={handleCloseModalErrorOrSuccess}
            onRequestCloseModalPassword={handleCloseModal}
            customDescription={
              customDescription !== "" ? customDescription : undefined
            }
            noOverlay
          />
        )}
      </div>
    </Layout>
  );
}
