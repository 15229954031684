import { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ModalErrorOrSucessMessage } from "../../../../components/ModalErrorOrSucessMessage";
import icone3D from '../../../../assets/icone3d.png';

import { ModalViewImageMobile } from "./ModalViewImageMobile";
import { is3dTypeUtils } from "../../../../utils/fileTypes/is3dTypeUtils";
import { isPdfTypeUtils } from "../../../../utils/fileTypes/isPdfTypeUtils";
import { isDicomTypeUtils } from "../../../../utils/fileTypes/isDicomTypeUtils";
import { isJpnAndPngUtils } from "../../../../utils/fileTypes/isJpgAndPngUtils";

import styles from "./styles.module.css";

export function ImageComponent({
  image,
  selected,
  handleImageClick,
  imageTitle,
  imageDate,
  handleOpenModalSheetChoice,
  imagensPaciente,
  isShare
}: any) {
  const location = useLocation();
  const queryString = location.search.substring(1);
  const queryParams = new URLSearchParams(queryString);
  const seqAtendParam = !queryParams.get("seqAtend") ? isShare.seqAtend : queryParams.get("seqAtend");
  const idPacienteParams = !queryParams.get("idPacient") ? isShare.idPaciente : queryParams.get("idPacient");
  const { id } = useParams<{ id: string }>();
  const ss = !id ? isShare.ss : id;

  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isModalWarningOpen, setModalWarningOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isMobileScreen = useMediaQuery({ query: "(max-width: 684px)" });
  let touchTimer = null;
  const idClient = !queryParams.get("idClient") ? isShare.idClient : queryParams.get("idClient");

  useEffect(() => {
    const imgElement = new Image();
    imgElement.src = `${image.link}`;
    imgElement.onload = () => {
      const width = imgElement.width;
      const height = imgElement.height;
      setImageDimensions({ width, height });
    };
  }, [image.link]);


  const imgStyles = useMemo(() => {
    return {
      width: isMobileScreen
        ? "100%"
        : imageDimensions.width > imageDimensions.height
          ? "432px"
          : "208px",
      height: isMobileScreen
        ? "168px"
        : imageDimensions.width > imageDimensions.height
          ? "320px"
          : "320px",
    }
  }, [imageDimensions]);

  const handleImageTouchStart = () => {
    touchTimer = setTimeout(() => {
      handleOpenModalSheetChoice();
    }, 1000);
  };

  const handleImageTouchEnd = () => {
    clearTimeout(touchTimer);
  };

  const handleOpenModalWarning = () => {
    setModalWarningOpen(true);
  };

  const handleCloseModalWarning = () => {
    setModalWarningOpen(false);
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const onRequestClose = () => {
    setIsOpen(false);
  };

  const handleCloseModalViewImageMobile = () => {
    setIsOpen(false);
  };


  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const is3DFile = (complemento) => is3dTypeUtils(complemento);

  const imgSrc = is3DFile(image.complemento)
    ? icone3D
    : isDicomTypeUtils(image.complemento)
      ? 'https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGegcUHzjvNXHCLnriloCmZ4=/dicom-viewer-2023-01-04%2000-00-00-2023-12-19%2013-44-53'
      : isJpnAndPngUtils(image.complemento)
        ? `${image.link}?tw=330&th=329`
        : image.icone;

  return (
    <div
      className={`${styles.containerImage} ${selected && styles.containerImage_active}`}
      onTouchStart={handleImageTouchStart}
      onTouchEnd={handleImageTouchEnd}
    >
      <input
        className={styles.inputCheckbox}
        type="checkbox"
        checked={selected}
        onChange={() => handleImageClick(image.link, image.nome)}
      />
      {(screenSize.width >= 1024 || image.complemento.endsWith(".stl")) ? (
        <Link
          to={
            !image.link.endsWith(".pdf") &&
            `/dashboard/visualizar-imagens/?ss=${ss}&id=${encodeURIComponent(
              idClient
            )}&atd=${seqAtendParam}&idPacient=${idPacienteParams}&tamanho=${image.tamanho}&image=${encodeURIComponent(image.link)}`
          }
          title={image.nome}
          style={{
            cursor: !!image.link.endsWith(".pdf") && "default",
          }}
        >
          <div style={{ position: 'relative', ...imgStyles }}>
            {isLoading && <div className={styles.loader}>
              <div />
              <div />
              <div />
              <div />
            </div>}
            <img
              src={imgSrc}
              style={{ ...imgStyles, display: isLoading ? 'none' : 'block' }}
              className={styles.image}
              onLoad={handleImageLoad}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = 'https://cdt.com.br/cli/p/getImagem.aspx?cmp=3889&nm=2';
              }}
              alt={image.nome}
            />
            <div
              style={{ width: imgStyles.width }}
              className={styles.imageFloatingText}
            >
              <h2 className={styles.imageTitle}>{imageTitle}</h2>
              <p className={styles.imageDate}>{imageDate}</p>
            </div>
            <div
              style={{
                ...imgStyles,
                ...(imageDimensions.height > imageDimensions.width &&
                  isMobileScreen
                  ? { top: "5%" }
                  : {}),
              }}
              className={styles.floatingGlass}
            />
          </div>
        </Link>
      ) : (
        <>
          <div
            onClick={() => {
              if (!is3dTypeUtils(image.complemento)) {
                setIsOpen(true);
              } else {
                handleOpenModalWarning();
              }
            }}
          >
            <div style={{ position: 'relative', ...imgStyles }}>
              {isLoading && <div className={styles.loader}>
                <div />
                <div />
                <div />
                <div />
              </div>
              }
              <img
                src={imgSrc}
                style={{ ...imgStyles, display: isLoading ? 'none' : 'block' }}
                className={styles.image}
                onLoad={handleImageLoad}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = 'https://cdt.com.br/cli/p/getImagem.aspx?cmp=3889&nm=2';
                }}
                alt={image.nome}
              />

              <div
                style={{ width: imgStyles.width }}
                className={styles.imageFloatingText}
              >
                <h2 className={styles.imageTitle}>{imageTitle}</h2>
                <p className={styles.imageDate}>{imageDate}</p>
              </div>

              <div
                style={{
                  ...imgStyles,
                  ...(imageDimensions.height > imageDimensions.width &&
                    isMobileScreen
                    ? { top: "5%" }
                    : {}),
                }}
                className={styles.floatingGlass}
              />
            </div>
          </div>

          {
            isOpen && (
              <ModalViewImageMobile
                isOpen={true}
                image={image.link}
                onRequestClose={handleCloseModalViewImageMobile}
                imagensPaciente={imagensPaciente}
                screenSize={screenSize}
              />
            )
          }

          {isModalWarningOpen && (
            <ModalErrorOrSucessMessage
              isOpen={isModalWarningOpen}
              type="mobileWarning"
              onRequestClose={handleCloseModalWarning}
              handleClose={handleCloseModalWarning}
            />
          )}
        </>
      )}
    </div>
  );
}
